<template>
  <div class="container-fluid">
    <HeaderBar />
    <div class="mf-menu-container">
      <transition name="fade">
        <router-view />
      </transition>
    </div>
    <!-- <FooterBar /> -->
  </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar/Index";

export default {
  name: "MainLayout",
  components: {
    HeaderBar,
  },
};
</script>

<style scoped lang="stylus">
.container
  width 100%

.container-fluid
  background-color: black
  padding-right: 0;
  padding-left: 0;

.fade-enter-active {
  transition: transform 1s, opacity 2s
}

.fade-enter {
  opacity 0
  transform: translateY(-40px)
}
</style>
