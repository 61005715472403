// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/ingeo-logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".menu-base-class{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100px;background-repeat:no-repeat;background-position:50%;height:150px}.menu-wrapper .title-name{background-color:hsla(0,0%,100%,.2);font-weight:600;margin:0;padding:.4em;border-radius:13px 13px 0 0}.menu-wrapper .menu-base-class{border-radius:0 0 13px 13px}.menu-wrapper .menu-base-class:hover,.menu-wrapper .title-name:hover{background-color:rgba(255,0,0,.4)}.menu-wrapper .level-circle{font-weight:700;text-align:center;border-radius:13px;background-color:#9ca5ad!important;-webkit-box-shadow:0 0 0 2px #6c757d;box-shadow:0 0 0 2px #6c757d}.menu-wrapper .level-circle:hover{-webkit-filter:drop-shadow(2px -2px 5px #d3d3d3);filter:drop-shadow(2px -2px 5px #d3d3d3);background-color:hsla(0,0%,100%,.6)!important}.menu-wrapper .menu-item-box{font-size:1.5em!important;font-family:Montserrat,Helvetica Neue,Arial,sans-serif}", ""]);
// Exports
module.exports = exports;
