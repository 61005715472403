<template>
  <div class="profile-view">
    <div class="profile-body">
      <BaseLoading v-if="isLoading" />

      <template v-if="profileData !== null">
        <MainBlock :profile-data="profileData" />
      </template>
    </div>
  </div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading";
import MainBlock from "./MainBlock/Index";
import moduleMenu from "./ModuleMenu";

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import { store } from "../../../../ingeo-mf-styleguide/src/store_";
/// #else
import { store } from "@ingeo-mf/styleguide";
/// #endif

export default {
  name: "ProfileView",
  components: {
    BaseLoading,
    MainBlock,
  },
  data() {
    return {
      isLoading: false,
      profileData: {
        menu: [
          {
            id: 1,
            sequence: 1,
            name: "Mapa",
            className: "mapa",
            menuName: "map",
            enabled: true,
          },
          {
            id: 2,
            sequence: 2,
            name: "Reportes",
            className: "reporte",
            menuName: "routes",
            enabled: true,
          },
          {
            id: 3,
            sequence: 3,
            name: "Telegestión",
            className: "comando",
            menuName: "command/ioapp",
            enabled: true,
          },
          {
            id: 4,
            sequence: 4,
            name: "Ajustes",
            className: "config",
            menuName: "config",
            enabled: true,
          },
          // {
          //   id: 4,
          //   sequence: 3,
          //   name: "Panel",
          //   className: "panel",
          //   menuName: "control-panel",
          //   enabled: true,
          // },
          // {
          //   id: 5,
          //   name: "Login",
          //   className: "help",
          //   menuName: "login",
          //   enabled: true,
          // },
        ],
      },
    };
  },
  created() {
    this.isLoading = false;
    const { region, battleTag: account } = this.$route.params;
  },
  mounted() {
    this.setMenuData(moduleMenu.menuItems);
    this.setModuleName(moduleMenu.moduleName);
  },
  methods: {
    setMenuData: store.mutations.setMenuItems,
    setModuleName: store.mutations.setModuleName,
  },
};
</script>
<style lang="stylus">
.profile-view
  padding-top 130px
  height: 100vh

.profile-body
  width: 100vw


</style>
