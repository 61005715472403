<template>
  <div class="about-view mb-5">
    <div class="text-center">
      <h1 class="font-diablo my-5">Ingeo Global</h1>

      <hr class="bg-dark mb-5" />

      <p class="lead">
        Seguimiento de vehículos.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutView",
};
</script>
