<template>
  <tr>

    <td class="img-row">
      <div class="img-wrapper">
        <img :src="this.imgFromType.find(o => o.type === task.type).iconImg" class="img-raised" :alt="task.title">
      </div>
    </td>
    <td class="text-left task-msg-body" style="font-size:16px;">{{task.veh}}</td>
    <td class="text-left task-msg-body" style="min-width:80px">{{task.date}}<div><p>{{task.time}}</p></div></td>
    <td class="text-left task-msg-body">{{task.title}}<div v-if="task.comment"><p>{{task.comment}}</p></div></td>
    <td>
      <p-checkbox v-model="task.done" @input="handleCheckUpdate">
      </p-checkbox>
    </td>
  </tr>
</template>
<script>
import { Tooltip } from 'element-ui'
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import PCheckbox from "../../../../../ingeo-mf-styleguide/src/UIComponents/Inputs/Checkbox";
/// #else
import { PCheckbox } from "@ingeo-mf/styleguide";
/// #endif

  export default {
    components: {
      [Tooltip.name]: Tooltip,
      PCheckbox,
    },
    data() {
        return {
            imgFromType : [
                {
                    type: 'INF',
                    iconImg: require('@/assets/img/icon-information.png')
                },
                {
                    type: 'ADV',
                    iconImg: require('@/assets/img/icon-warning.png')
                },
                {
                    type: 'ALE',
                    iconImg: require('@/assets/img/icon-alert.png')
                },
                {
                    type: 'URG',
                    iconImg: require('@/assets/img/icon-alert.png')
                }
            ]
        }
    },
    props: {
      task: {
        type: Object,
        default: () => {
          return {
            title: ''
          }
        }
      },
      index: Number
    },
    methods: {
      handleEditClick() {
        this.$emit('on-edit', this.index)
      },
      handleDeleteClick() {
        this.$emit('on-delete', this.index)
      },
      handleCheckUpdate(){
        this.$emit('on-ack', this.index)
      }
    }
  }
</script>
<style scoped>
.task-msg-body{
    font-weight: bolder;
}
</style>
