<template>
  <div
    class="menu-wrapper mb-5 mb-sm-5 mr-sm-2 hover-cursor-pointer"
    @click="goToModule(menu.menuName)"
  >
    <div class="bg-secondary justify-content-center level-circle">
      <h5 class="text-truncate text-center title-name font-sans">
        {{ menu.name }}
      </h5>
      <div
        class="menu-base-class center"
        :style="{
          'background-image':
            'url(' +
            require(`@/assets/img/modules/${menu.className}.png`) +
            ')',
        }"
      ></div>
    </div>
    <!-- <div class="p-2 menu-item-box">
      <h5
        class="text-truncate m-0 text-center title-name font-sans"
        :class="{ 'bg-danger': menu.hardcore }"
      >
        {{ menu.name }}
      </h5>
    </div> -->
  </div>
</template>

<script>
import goToModule from "@/mixins/goToModule";

export default {
  name: "TopMenu",
  mixins: [goToModule],
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  computed: {
    menuClass() {
      const status = this.menu.enabled ? "enabled" : "disabled";
      return `menu-${this.menu.className} ${status}`;
    },
  },
};
</script>

<style lang="stylus">

.menu-base-class
  background-image: url('../../../../assets/img/ingeo-logo.png')
  background-size 100px
  background-repeat no-repeat
  background-position center
  height 150px
  // width 150px

.menu-wrapper
  .title-name
    background-color: rgba(255,255,255,0.2)
    // color white
    font-weight 600
    margin 0
    padding .4em
    border-radius 13px 13px 0 0

  .menu-base-class
    border-radius 0 0 13px 13px

  .title-name:hover,
  .menu-base-class:hover
    background-color: rgba(255,0,0,0.4)

  .level-circle
    // padding 4px
    font-weight bold
    text-align center
    border-radius 13px
    background-color #9ca5ad !important
    box-shadow 0 0 0 2px #6c757d

  .level-circle:hover
    filter drop-shadow(2px -2px 5px lightgray)
    background-color rgba(255,255,255,0.6) !important

  .menu-item-box
    font-size: 1.5em !important;
    font-family: "Montserrat","Helvetica Neue",Arial,sans-serif
</style>
