<template>
  <card>
    <template slot="header">
        <!-- <h4 class="card-title">Notificaciones</h4> -->
      <div>
        <!-- <h4 class="card-title">Vehículo</h4> -->
        <fg-input v-if="this.vehiculosDisponibles">
          <multiselect
            v-model="multiSelectValue"
            :options="vehiculos"
            :multiple="true"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            group-values="grupoVeh"
            group-label="name"
            :group-select="true"
            placeholder="Filtrar por vehículos"
            deselectGroupLabel="Deseleccionar el Grupo"
            deselectLabel="Deseleccionar el vehículo"
            selectedLabel="Seleccionado"
            selectGroupLabel="Seleccionar el Grupo"
            selectLabel="Seleccionar el vehículo"
            track-by="pat"
            label="pat"
            :max-height="200"
          >
            <span slot="noResult">No se encontró ningún vehículo..</span>
          </multiselect>
        </fg-input>
      </div>
    </template>
    <div id="eventList" class="table-full-width table-tasks" @scroll="scroll">
      <table class="table">
        <tbody>
          <task
            v-for="(task, index) in visibleTasks"
            :key="task.id"
            :task="task"
            :index="index"
            @on-edit="handleTaskEdit"
            @on-delete="handleTaskDelete"
            @on-ack="handleTaskAck"
          ></task>
        </tbody>
      </table>
    </div>

    <template slot="footer">

      <!-- <hr /> -->
      <div class="stats" v-if="this.showSettingMenu">
        <tr >
            <td class="img-row">
            <div class="img-wrapper">
                <img src="@/assets/img/icon-information.png" :class="filterINF?'':'blur-grayscale'" class="img-raised inner-img " @click="toggleFilterINF">
            </div>
            </td>
            <td class="img-row">
            <div class="img-wrapper">
                <img src="@/assets/img/icon-warning.png" :class="filterADV?'':'blur-grayscale'" class="img-raised inner-img " @click="toggleFilterADV">
            </div>
            </td>
            <td class="img-row">
            <div class="img-wrapper">
                <img src="@/assets/img/icon-alert.png" :class="filterURG?'':'blur-grayscale'" class="img-raised inner-img " @click="toggleFilterURG">
            </div>
            </td>
            <td class="img-row">
            <div class="img-wrapper">
                <img src="@/assets/img/icon-check-square.png" :class="filterACK?'':'blur-grayscale'" class="img-raised inner-img " @click="toggleFilterACK">
            </div>
            </td>
            </tr>
        </div>
        <div class="stats" v-else>
            <i class="fa fa-history"></i> Actualizado hace 30 segundos
        </div>
      <div class="stats" id="icon-config-notifications">
        <a class=" btn-rotate" @click="toggleSettingMenu">
            <i class="nc-icon lg nc-settings-gear-65"></i>
        </a>
      </div>

    </template>
  </card>
</template>
<script>

import Multiselect from "vue-multiselect";
import Task from "./Task.vue";
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import {
  FgInput,
  Card,
} from "../../../../../ingeo-mf-styleguide/src/UIComponents";
import mensajeService  from "../../../../../ingeo-mf-styleguide/src/services/mensaje";
import vehiculosService from "../../../../../ingeo-mf-styleguide/src/services/vehiculo";
/// #else
import { FgInput, Card } from "@ingeo-mf/styleguide";
import { mensajeService, vehiculosService } from "@ingeo-mf/styleguide";
/// #endif


export default {
  components: {
    Task,
    Card,
    FgInput,
    Multiselect
  },
  data() {
    return {
      tasks: [],
      isUpdating: false,
      vehiculos: [],
      vehiculosDisponibles: false,
      multiSelectValue: "",
      showSettingMenu: false,
      filterURG: true,
      filterADV: true,
      filterINF: true,
      filterACK: true,
      bottom: '',
      interval:'',
      grupos: [],

    };
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.interval)

  },
  watch: {
    $route(to, from) {
      this.handleResize();
    }
    // bottom(bottom) {
    //   if (bottom && !this.isUpdating) {
    //       this.isUpdating = true;
    //       console.log("scroll");
    //       let lastMessageId = this.tasks[this.tasks.length - 1].id;
    //       this.getMoreMessages(lastMessageId);
    //   }
    // }
  },
  computed: {
    // ...mapState(["grupos"]),
    visibleTasks: function () {
        var filterList = this.tasks
        if (this.multiSelectValue.length) {
            filterList = filterList.filter(i => {
                return this.multiSelectValue.some(item => item.pat === i.veh)
            })
        }
        if (!this.filterACK) {
            filterList = filterList.filter(i => !i.done )
        }
        if (!this.filterURG) {
            filterList = filterList.filter(i => i.type !== 'URG' )
        }
        if (!this.filterADV) {
            filterList = filterList.filter(i => i.type !== 'ADV' )
        }
        if (!this.filterINF) {
            filterList = filterList.filter(i => i.type !== 'INF' )
        }
        return filterList
    },
  },
  methods: {
    handleResize() {
      let navbarHeight = 60;//document.getElementById("navbar").offsetHeight;
      let windowHeight = window.innerHeight;

      let footerOffset = 155;
      if (this.$router.history.current.path === "/home") footerOffset += 150;

      let contentHeight = windowHeight - navbarHeight - footerOffset;
      document.getElementById("eventList").style.height = contentHeight + "px";
    },
    handleTaskEdit(index) {
      this.tasks[index].comment = "comentario del evento";
      alert(`You want to edit task: ${JSON.stringify(this.tasks[index])}`);
    },
    handleTaskDelete(index) {
      alert(`You want to delete task: ${JSON.stringify(this.tasks[index])}`);
    },
    handleTaskAck(index) {
      if (this.tasks[index].done) {
        mensajeService
          .setMensajeAck(this.tasks[index].id)
          .then(res => {
            console.log("message ack: ", this.tasks[index].id);
          })
          .catch(
            // TODO: error handler
            error => {
              console.log("no se pudo setear ack" + error);
            }
          );
      }
    },
    getLastMessages() {
      mensajeService
        .mensajes(30)
        .then(res => {
          for (var msg = 0; msg < res.data.length; msg++) {
            let itemObj = {
              id: res.data[msg].mensaje_id,
              done: res.data[msg].usr_id ? true : false,
              type: res.data[msg].criticidad_id,
              veh: res.data[msg].veh_id,
              title: res.data[msg].mensaje_detalle,
              date: this.getDateFromDateTime(res.data[msg].mensaje_fecha_hora),
              time: this.getTimeFromDateTime(res.data[msg].mensaje_fecha_hora),
              info: res.data[msg].mensaje_info_plus,
              comment: "",
            };
            this.tasks.push(itemObj);
          }
          this.updateBadge(this.tasks.length);
        })
        .catch
        // TODO: error handler
        ();
    },
    getDateFromDateTime(dateTime) {
      let newdate = new Date(dateTime);
      return (
        newdate.getDate() +
        "-" +
        (newdate.getMonth() + 1) +
        "-" +
        String(newdate.getFullYear()).substr(2, 2)
      );
    },
    getTimeFromDateTime(dateTime) {

        var date = new Date(dateTime)
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = (hours >= 12) ? 'pm' : 'am'
        hours = hours % 12;
        hours = hours ? hours : 12
        minutes = ( minutes < 10 ) ? '0'+ minutes : minutes
        return hours + ':' + minutes + ' ' + ampm;

    },
    scroll() {
        let scPos = parseInt(document.getElementById("eventList").scrollTop + document.getElementById("eventList").clientHeight)
        let scHeight = parseInt(document.getElementById("eventList").scrollHeight)
        //console.log(scPos, scHeight)

        if (scPos === scHeight && !this.isUpdating) {
          this.isUpdating = true;
          console.log("scroll");
          let lastMessageId = this.tasks[this.tasks.length - 1].id;
          this.getMoreMessages(lastMessageId);
        }
    },
    getMoreMessages(lastId) {
      mensajeService
        .masMensajesPrevios(lastId, 20)
        .then(res => {
          for (var msg = 0; msg < res.data.length; msg++) {
            let itemObj = {
              id: res.data[msg].mensaje_id,
              done: res.data[msg].usr_id ? true : false,
              type: res.data[msg].criticidad_id,
              veh: res.data[msg].veh_id,
              title: res.data[msg].mensaje_detalle,
              date: this.getDateFromDateTime(res.data[msg].mensaje_fecha_hora),
              time: this.getTimeFromDateTime(res.data[msg].mensaje_fecha_hora),
              info: res.data[msg].mensaje_info_plus,
              comment: "",
            };
            this.tasks.push(itemObj);
          }
          this.isUpdating = false;
          this.updateBadge(this.tasks.length);
        })
        .catch(error => {
          this.isUpdating = false;
          console.log("catch updating..", error);
        });
    },
    getNewestMessages() {
      var firstId = this.tasks[0].id
      mensajeService
        .masMensajesNuevos(firstId)
        .then(res => {
          for (var msg = 0; msg < res.data.length; msg++) {
            let itemObj = {
              id: res.data[msg].mensaje_id,
              done: res.data[msg].usr_id ? true : false,
              type: res.data[msg].criticidad_id,
              veh: res.data[msg].veh_id,
              title: res.data[msg].mensaje_detalle,
              date: this.getDateFromDateTime(res.data[msg].mensaje_fecha_hora),
              time: this.getTimeFromDateTime(res.data[msg].mensaje_fecha_hora),
              info: res.data[msg].mensaje_info_plus,
              comment: "",
            };
            this.tasks.unshift(itemObj);
          }
          this.isUpdating = false;
          this.updateBadge(this.tasks.length);
        })
        .catch(error => {
          this.isUpdating = false;
          console.log("catch updating..", error);
        });
    },
    formatData() {
      var i;
      var j;
      for (i = 0; i < this.grupos.length; i++) {
        var grupo = {
          name: this.grupos[i].grupo_nombre,
          grupoVeh: []
        };
        for (j = 0; j < this.grupos[i].grupos_vehiculos.length; j++) {
          var veHSel = {
            pat: this.grupos[i].grupos_vehiculos[j].veh_id,
            key: this.grupos[i].grupos_vehiculos[j].veh_id
          };
          grupo.grupoVeh.push(veHSel);
        }
        this.vehiculos.push(grupo);
      }
      this.vehiculosDisponibles = true;
    },
    toggleSettingMenu() {
        this.showSettingMenu = !this.showSettingMenu
    },
    toggleFilterINF() {
        this.filterINF = !this.filterINF
    },
    toggleFilterURG() {
        this.filterURG = !this.filterURG
    },
    toggleFilterADV() {
        this.filterADV = !this.filterADV
    },
    toggleFilterACK() {
        this.filterACK = !this.filterACK
    },
    startPeriodicQuery () {
        let self = this
        this.interval = setInterval(function () {
            self.getNewestMessages()
        }, 15000)
    },
    updateBadge (newValue) {
          this.$emit('badge-update', newValue)
    }
  },
  created() {
    this.getLastMessages();

  },
  mounted() {
    // this.scroll();
    window.addEventListener("resize", this.handleResize);
    this.handleResize()
    vehiculosService.index().then(res => {
      this.grupos = res.data;
      // this.$store.commit("setGrupos", { gruposVehiculos: res.data });
      this.formatData()
      this.startPeriodicQuery()
    });
  }
};
</script>
<style>
.table-tasks {
  overflow: scroll;
  overflow-y: scroll;

  /* TODO: Ajuste automático de altura */
  /* max-height: 500px; */
}
.card {
  border-radius: 0px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.65);
  background-color: #f0f0f0;
}
#icon-config-notifications{
    float: right;
    height: 30px;
}

.blur-grayscale {
    filter: blur(2px) grayscale(1);
    -webkit-filter: blur(2px) grayscale(1);
    -moz-filter: blur(5px) grayscale(1);
    -o-filter: blur(5px) grayscale(1);
    -ms-filter: blur(5px) grayscale(1);
}
.img-wrapper {
  width: 50px;
  height: 50px;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
}
.img-wrapper img {
  width: 50px;
}

.inner-img {
  transition: 0.3s;
}

.inner-img:hover {
  transform: scale(1.1);
}
.stats {
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: top;
    font-size: 12px;
}

</style>
