<template>
      <!-- 
      @mouseover.native="onOver"
      @mouseleave.native="onLeave"
      split
      split-href="#"
      @click="goTo('menu')"
      offset="50"

      TODO ocultar logo en resolucion SM (small)
      Sino restituir lienas anteriores....
       -->
  <div class="dropdown">
    <b-dropdown
      @mouseover.native="onOver"
      @mouseleave.native="onLeave"
      @toggle="goTo('menu')"
      no-caret
      variant="link"
      toggle-class="text-decoration-none"
      menu-class="module-dropdown-menu"
      ref="moduleMenuDropdown"
    >
      <template v-slot:button-content>
        <img
          class="d-none d-md-block"
          src="@/assets/img/ingeo-logo.png"
          title="Home"
          alt="D3"
          width="30"
        />
        <div class="d-md-none">Home</div>
      </template>

      <b-dropdown-item href="#" @click="goTo('menu')">
        <span class="module-menu-text">Home</span>
      </b-dropdown-item>

      <b-dropdown-divider></b-dropdown-divider>

      <b-dropdown-item href="#" @click="goTo('map')">
        <span class="module-menu-text">Mapa</span>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="goTo('routes')">
        <span class="module-menu-text">Reportes</span>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="goTo('command/ioapp')">
        <span class="module-menu-text">Telegestión</span>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="goTo('config')">
        <span class="module-menu-text">Ajustes</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    goTo(moduleName) {
      this.$router.push({ path: `/${moduleName}` });
    },
    // toggleMenu() {
    //   this.isClosed = !this.isClosed;
    // },
    onOver() {
      this.$refs.moduleMenuDropdown.visible = true;
    },
    onLeave() {
      this.$refs.moduleMenuDropdown.visible = false;
    },
  },
};
</script>

<style>
.nav.nav-menu {
  margin-top: 0;
}
.module-menu-text {
  color: black;
  padding-left: 10px;
}
.module-menu-icon {
  color: black;
}
.module-dropdown-menu {
  border-radius: 0 !important;
  top: -2px !important;
}

/* .module-dropdown-menu .dropdown-item:focus, */
.module-dropdown-menu .dropdown-item:hover
 {
  background-color: lightslategray !important;
  border-radius: 0 !important;
}

.ingeo-logo-home {
  font-size: 1.5em;
}
.btn-group button {
  margin-left: -10px !important;
}

.btn-group .dropdown-toggle-split {
  margin-left: -15px !important;
}
.btn.dropdown-toggle.btn-link.dropdown-toggle-split {
  padding-left: 15px !important;
}
</style>
