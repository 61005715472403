<template>
  <div class="loading-layout">
    <BaseLoading />
  </div>
</template>

<script>
import BaseLoading from "@/components/BaseLoading.vue";

export default {
  name: "LoadLayout",
  components: {
    BaseLoading,
  },
};
</script>

<style lang="stylus">
.loading-layout
  min-height 100vh
  display flex
  align-items center
  justify-content center
</style>
