import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";

// BootstrapVue
import "./plugins/bootstrapVue";

// Vue Font-Awesome
import "./plugins/fontAwesome";


import App from "./App.vue";
import router from "./router";
import store from "./store";

// Global CSS
import "./assets/css/main.styl";

////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
/////////
import { TokenService } from "@ingeo-mf/styleguide";
//////////

console.log("Running mode: ", process.env.VUE_APP_DEVMODE);

////////////////////////////////////////////////////
/////////
/////////
////////
////////////
////////////
///////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
//////
////
/////////////
////////////////
////
////////////////////////
//////////////////

/////////

Vue.config.productionTip = false;
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      // TokenService.saveToken(this.token);
      return h(App, { props: { token: this.token } });
    },
    router,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

//////////
