import Vue from "vue";
import Router from "vue-router";
import MenuModule from "../views/Menu/Index";
import LoginModule from "../views/Login/Index";
import ErrorModule from "../views/Error/Index";
import AboutModule from "../views/About/Index";
import LoadLayout from "../layouts/LoadLayout";
import MainLayout from "../layouts/MainLayout";
import LoginLayout from "../layouts/LoginLayout";
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
/////////
import { authService } from "@ingeo-mf/styleguide";
//////////

Vue.component("main-layout", MainLayout);
Vue.component("login-layout", LoginLayout);
Vue.component("load-layout", LoadLayout);
Vue.use(Router);

const routes = [
  { path: "/", name: "Home", exact: true, redirect: "/menu"},
  {
    path: "/menu",
    name: "Menu",
    component: MenuModule,
    meta: {
      layout: "main",
      public: false,
      onlyWhenLoggedOut: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginModule,
    meta: {
      layout: "login",
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorModule,
    meta: {
      layout: "login",
      public: true,
      onlyWhenLoggedOut: false,
    },
  },
  {
    path: "/about",
    name: "About",
    component: AboutModule,
    meta: {
      layout: "login",
      public: true,
      onlyWhenLoggedOut: false,
    },
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  next(authService.checkPermission(to));
});

export default router;
