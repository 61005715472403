<template>
  <div class="login-layout">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "LoginLayout",
};
</script>

<style lang="stylus">
.login-layout
  width 100%
  min-height 100vh
  display flex
  align-items center
  justify-content center

.fade-enter-active {
  transition: transform 1s, opacity 2s
}

.fade-enter {
  opacity 0
  transform: translateY(-40px)
}
</style>
