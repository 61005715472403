<template>
  <div class="dropdown">
    <b-dropdown
      right
      size="sm"
      variant="link"
      toggle-class="text-decoration-none"
      menu-class="user-dropdown-menu"
      no-caret
    >
      <template v-slot:button-content>
        <div class="photo dropdown-toggle no-caret" @click="toggleMenu">
          <img
            src="@/assets/img/icon-user.png"
            alt="Perfil de Usuario"
            height="35"
          />
        </div>
      </template>
      <b-dropdown-header id="dropdown-header-label">
        Usuario:
      </b-dropdown-header>
      <b-dropdown-item href="#">
        <span class="user-menu-icon">
          <i class="nc-icon nc-circle-10"></i>
        </span>
        <span class="user-menu-text"
          >{{ this.user.name }} {{ this.user.lastname }}</span
        >
      </b-dropdown-item>
      <b-dropdown-item href="#">
        <span class="user-menu-icon">
          <i class="nc-icon nc-email-85"></i>
        </span>
        <span class="user-menu-text" style="text-transform: none;">{{
          this.user.email
        }}</span>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="goToSettings()">
        <span class="user-menu-icon">
          <i class="nc-icon nc-settings-gear-65"></i>
        </span>
        <span class="user-menu-text">Preferencias</span>
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="goToHelp()">
        <span class="user-menu-icon">
          <i class="nc-icon nc-support-17"></i>
        </span>
        <span class="user-menu-text">Ayuda</span>
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href="#" @click="userLogout">
        <span class="user-menu-icon">
          <i class="nc-icon nc-button-power"></i>
        </span>
        <span class="user-menu-text">Salir</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>

// import authService from "@/services/user";
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import authService from "../../../../ingeo-mf-styleguide/src/services/auth";
/// #else
import { authService } from "@ingeo-mf/styleguide";
/// #endif

export default {
  mounted() {
    this.getUserInfo();
  },
  data() {
    return {
      user: {
        username: "",
        name: "",
        lastname: "",
        email: "",
      },
      isClosed: true,
    };
  },
  methods: {
    goToSettings() {
      this.$router.push({ path: "/settings" });
    },
    goToHelp() {
      this.$router.push({ path: "help" });
    },
    userLogout() {
      authService.logout();
      this.$router.push({ path: "/login" });
    },
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    getUserInfo() {
      authService.userInfo().then((res) => {
        this.user.username = res.data.username;
        this.user.name = res.data.usr_nombre;
        this.user.lastname = res.data.usr_apellido;
        this.user.email = res.data.email;
      });
    },
  },
};
</script>
<style>
/* .nav.nav-menu {
  margin-top: 0;
} */
/* .user {
  margin-top: -10px;
} */
.user-menu-text {
  color: black;
  padding-left: 10px;
}
.user-menu-icon {
  color: black;
}
.user-dropdown-menu {
  border-radius: 0 !important;
  top: 5px !important;
}

.user-dropdown-menu .dropdown-item:hover,
.user-dropdown-menu .dropdown-item:focus {
  background-color: lightslategray !important;
  border-radius: 0 !important;
}
</style>
