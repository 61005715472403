<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
              <!-- <form> -->
                <card type="login">
                  <h3 slot="header" class="header text-center">
                    Autenticación
                  </h3>

                  <fg-input
                    v-model="userInput"
                    addon-left-icon="nc-icon nc-single-02"
                    placeholder="Usuario / Email"
                  ></fg-input>

                  <fg-input
                    v-model="passwordInput"
                    addon-left-icon="nc-icon nc-key-25"
                    placeholder="Contraseña"
                    type="password"
                  ></fg-input>
                  <!-- <br> -->
                  <!-- TODO acá se debería mostrar la opción de mantenerme logueado -->
                  <!-- <p-checkbox>
                    Subscribe to newsletter
                  </p-checkbox> -->
                  <p-button
                    native-type="submit"
                    slot="footer"
                    type="ingeo"
                    round
                    block
                    class="mb-3"
                    >Ingresar</p-button
                  >
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <!-- TODO cambiar fondo de login -->
        <div class="full-page-background"></div>
      </div>
    </div>
  </div>
</template>
<script>

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import {
  FgInput,
  Card,
  Checkbox,
  PButton,
} from "../../../../ingeo-mf-styleguide/src/UIComponents"
import authService from "../../../../ingeo-mf-styleguide/src/services/auth";
import TokenService from "../../../../ingeo-mf-styleguide/src/services/token";
/// #else
import { FgInput, Card, Checkbox, PButton } from "@ingeo-mf/styleguide";
import { authService, TokenService } from "@ingeo-mf/styleguide";
/// #endif
import AppNavbar from "./AppNavbar";
import AppFooter from "./AppFooter";
import swal from "sweetalert2";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    FgInput,
    [Checkbox.name]: Checkbox,
    [PButton.name]: PButton,
  },
  methods: {
    loginError(error) {
      var mensajeLogin = "";
      var mensajeUnauthorized = "";
      var mensajePassword = "";
      if (error.errors) {
        if (error.errors.login) {
          // mensajeLogin = error.errors.login[0]
          mensajeLogin = "El campo Usuario / Email es obligatorio.";
        }
        if (error.errors.password) {
          // mensajePassword = error.errors.password[0]
          mensajePassword = "El campo Contraseña es obligatorio.";
        }
      } else {
        mensajeUnauthorized = error.message;
      }
      swal({
        title: `Error!`,
        html:
          mensajeLogin +
          "<br>" +
          mensajePassword +
          "<br>" +
          mensajeUnauthorized,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-danger btn-fill",
        type: "error",
      });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    login() {
      authService
        .login(this.userInput, this.passwordInput)
        .then((res) => {
          TokenService.saveTokenAsBearer(res.data.access_token);
          this.$router.push({ name: "Menu" });
        })
        .catch((error) => {
          this.loginError(error);
        });
    },
  },
  data() {
    return {
      userInput: "",
      passwordInput: "",
    };
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style scoped>
.content {
  height: 100vh;
}
.container {
  display: flex;
  align-items: center;
  height: 100%;
}

.login-page {
  width: 100vw;
  height: 100vh;
  background-size: cover;
}
</style>
