<template>
  <div class="menu-box container">
    <b-row class="justify-content-center">
      <b-col class="col-sm-6" v-for="menu in menuItems" :key="menu.id">
        <TopMenu :menu="menu" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TopMenu from "./TopMenu";

export default {
  name: "TopMenus",
  components: { TopMenu },
  props: {
    menuItems: {
      required: true,
      type: Array,
    },
  },
};
</script>
