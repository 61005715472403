<template>
  <b-nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
    <b-navbar-toggle target="nav-collapse order-0"></b-navbar-toggle>

    <b-navbar-nav class="ml-auto mt-0 flex-row align-items-center order-1 order-md-2">
      <li class="nav-item h-100">
          <a class="nav-link"
              href="#"
              @click="toggleNotificationDropDown()">
            <img
              src="@/assets/img/icon-bell-notification-fill.png"
              alt="Mensajes"
              height="35"
            />
            <span class="badge badge-light badge-messages">{{unreadMessages}}</span>
        </a>
      </li>
      <li class="nav-item h-100">
        <UserMenu></UserMenu>
      </li>
    </b-navbar-nav>

    <b-collapse id="nav-collapse" class="order-2 order-md-1" is-nav>
      <b-navbar-nav class="mr-auto align-items-center nabvar-menu-collapsable">
        <!-- <b-nav-form> -->

          <!-- <ul class="navbar-nav mr-0 d-lg-flex align-items-center"> -->
          <li class="nav-item h-100 mt-n0 nabvar-main-menu">
            <MainMenu></MainMenu>
          </li>

          <div class="mx-1 order-0 mt-n0 ml-3 mr-4 navbar-module-name">
            <a class="navbar-brand mx-auto" href="#">
              <slot name="title">{{getModuleName}}</slot>
            </a>
          </div>

          <li class="nav-item h-100 mt-n1" v-for="data in getMenuItems" :key="data.id">
            <template v-if="data.type === 'link'">
              <a class="nav-link mt-2 mr-2 ml-2" :href="data.url">
                {{ data.title }}
              </a>
            </template>

            <template v-if="data.type === 'dropdown'">
              <b-dropdown
                size="sm"
                variant="link"
                toggle-class="text-decoration-none"
                menu-class="user-dropdown-menu"
                class="mt-2 mr-2 ml-2"
                no-caret
                :text="data.title"
              >
                <template v-if="data.items.length">
                  <template v-for="dataItem in data.items">
                    <b-dropdown-header
                      :key="dataItem.id"
                      v-if="dataItem.type === 'header'"
                      id="dropdown-header-label"
                    >
                      {{ dataItem.name }}
                    </b-dropdown-header>

                    <b-dropdown-item
                      :key="dataItem.id"
                      v-if="dataItem.type === 'link'"
                      href="#"
                      @click="goTo(dataItem.url)"
                    >
                      <span class="user-menu-text">{{ dataItem.name }}</span>
                    </b-dropdown-item>

                    <b-dropdown-divider
                      :key="dataItem.id"
                      v-if="dataItem.type === 'divider'"
                    >
                    </b-dropdown-divider>
                  </template>
                </template>
              </b-dropdown>
            </template>
          </li>
      </b-navbar-nav>

    </b-collapse>

    <div id="notifications-container" 
        v-if="this.activeNotifications" 
        class=" event-notify-list col-lg-4 col-md-8 text-center">
      <task-list @badge-update="onUpdateBadge"></task-list>
    </div>

  </b-nav>
</template>

<script>
import UserMenu from "./UserMenu";
import MainMenu from "./MainMenu";
import TaskList from './MessageList/TaskList';

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import { store } from "../../../../ingeo-mf-styleguide/src/store_";
/// #else
import { store } from "@ingeo-mf/styleguide";
/// #endif

export default {
  components: {
    UserMenu,
    MainMenu,
    TaskList,
  },
  data() {
    return {
      currentSeason: null,
      activeNotifications: false,
      unreadMessages: 0,
    };
  },
  computed: {
    getMenuItems() {
      return store.store.menuItems;
    },
    getModuleName() {
      return store.store.moduleName;
    },
  },
  methods: {
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    onUpdateBadge(value){
      this.unreadMessages = value;
    },
    goTo(moduleName) {
      this.$router.push({ path: `/${moduleName}` });
    },
  },
};
</script>

<style>
.navbar-brand {
  /* text-transform: uppercase; */
  font-size: 1.5em !important;
  font-family: "Montserrat","Helvetica Neue",Arial,sans-serif;
}
.navbar-toggler-icon {
  margin-left: -10px !important;
  margin-top: -20px !important;
}
.navigation-bar {
  height: 100%;
}
.navbar {
  padding-top: 0;
  padding-bottom: 0;
  height: 60px;
}

.nav-link {
  font-size: 0.8571em !important;
}

.btn.btn-link
{
  color: #eaeaea !important;
  font-weight: 600 !important;
}

.event-notify-list{
 float: right !important;
 position: absolute !important;;
 right: -10px;
 top: 63px;
 height: 100%;
}

.badge-messages {
  vertical-align: super;
}

.btn.btn-link:hover, a.nav-link:hover {
  cursor: pointer;
  filter:drop-shadow(2px -2px 5px lightgray);
}

@media screen and (max-width: 768px){
  .navbar {
    height: auto;
  }
  .nabvar-menu-collapsable {
    -ms-flex-align: start !important;
    align-items: start !important;
    margin-left: 1rem;
  }
  .nabvar-menu-collapsable li a {
    margin-left: -10px;
  }
  /* .nabvar-main-menu .b-dropdown button {
    display: none;
  } */
  /* .navbar-module-name {
    display: none;
  } */
  
}

</style>
