<template>
  <div class="grid-container">
    <div class="grid-item item-left">
      <TopMenu v-if="hasMenu" :menuItems="topMenus" />
    </div>
  </div>
</template>

<script>
import TopMenu from "./TopMenu/Index";

export default {
  name: "MainBlock",
  components: { TopMenu },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasMenu() {
      return this.profileData.menu.length > 0;
    },
    topMenus() {
      return this.orderMenuItems();
    },
  },
  methods: {
    orderMenuItems() {
      return this.profileData.menu.sort((a, b) => a.sequence - b.sequence);
    },
  },
};
</script>

