<template>
  <div id="app-spa">
    <component :is="layout"></component>
  </div>
</template>

<script>
import { mapState } from "vuex";
const default_layout = "main";

export default {
  name: "App",
  props: {
    token: String,
  },
  computed: {
    ...mapState("loading", {
      isLoading: "isLoading",
    }),
    layout() {
      if (this.isLoading) return "load-layout";
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>

<style lang="stylus">
body
  // background-color black !important
#app
  width 100%
  padding 0
  font-family 'Montserrat','Avenir', Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  // color #ffffff
  // background-color black !important
</style>
