<template>
  <navbar navbar-menu-classes="navbar-right" v-model="showMenu">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="#">Ingeo Global</a>
    </div>
    <template slot="navbar-menu">
      <router-link to="/admin" class="nav-item" tag="li">
        <a class="nav-link"> <i class="nc-icon nc-email-85"></i> Contacto </a>
      </router-link>
    </template>
  </navbar>
</template>

<script>
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import Navbar from "../../../../ingeo-mf-styleguide/src/UIComponents/Navbar/Navbar.vue";
/// #else
import Navbar from "@ingeo-mf/styleguide";
/// #endif

export default {
  name: "main-navbar",
  components: {
    Navbar,
  },
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<style scoped></style>
